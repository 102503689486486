import React, { useCallback, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import Spinner from '@/components/v2/Spinner.jsx';
import { useRipple } from '@/hooks/useRipple.jsx';

const classNames = {
    variants: {
        primary: 'border bg-[#AAC1B7] border-[#AAC1B7] text-[#343132]',
        border: 'border text-[#212121] border-[#34313266] hover:bg-[#212121] hover:text-[#FFFFFF]',
        blur: 'text-[#FFFFFF] bg-[#FFFFFF]/20 backdrop-blur-md hover:bg-[#FFFFFF]/90 hover:text-[#212121]',
        white: 'bg-[#FFFFFF] text-[#212121]',
    },
    sizes: {
        small: 'size-10 ',
        normal: 'size-12',
        large: 'size-14',
    },
};

const Button = ({ variant = 'border', size = 'normal', children, className, disabled = false, loading = false, type = 'button', onClick, ...properties }) => {
    const buttonRef = useRef(null);
    const { create: createRipple, render: renderRipple } = useRipple();

    const handleClick = useCallback(
        (event) => {
            if (!disabled && !loading) {
                createRipple(event);
                if (onClick) {
                    onClick(event);
                }
            }
        },
        [onClick, createRipple, disabled, loading],
    );

    return (
        <motion.button
            ref={buttonRef}
            className={twMerge('relative w-fit font-semibold overflow-hidden z-0 rounded-lg', classNames.variants[variant], classNames.sizes[size], (disabled || loading) && 'pointer-events-none opacity-75', 'transition-all', className)}
            onClick={handleClick}
            disabled={disabled || loading}
            type={type}
            {...properties}
        >
            <div className='relative z-10'>
                <div className={`flex items-center justify-center gap-2 whitespace-nowrap ${loading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-200`}>{children}</div>
                <AnimatePresence>
                    {loading && (
                        <motion.div key='spinner' animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className='absolute inset-0 flex items-center justify-center'>
                            <Spinner />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {renderRipple()}
        </motion.button>
    );
};

export default Button;
